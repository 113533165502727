import { gql } from "@apollo/client";

export const GET_DISTRIBUTIONS = gql`
  query distributions($symbol: String) {
    distributions(symbol: $symbol) {
      id
      active
      name
      amount
      amountUnit
      splitPaymentUnit
      distributionType
      paymentMethod
      regularOrSpecial
      splitPaymentCashValue
      splitPaymentSecuritiesValue
      offerSymbol
      registryDaysBeforePayout
      firstPayoutDate
      paidOut
      approvers {
        name
        email
      }
      nextRegistryDate
    }
  }
`;

export const GET_ACCOUNT_USERS = gql`
  query accountUsers($accountId: String, $disabled: Boolean) {
    accountUsers(accountId: $accountId, disabled: $disabled) {
      id
      type
      userId
      email
      accountId
      title
      isDirector
      shareholderPercentageOwnership
      canLogin
      permissions
      user {
        lastLogin
        authState
        acceptedPrivacy
        acceptedRDI
        acceptedTerms
        createdAt
        email
        emailVerified
        id
        requiresKYC
        kyc {
          status
          provider
          veriffId
          onfidoReport
          veriffReport
          plaidReport
        }
        name
        passportNumber
        phone
        sin
        updatedAt
      }
    }
  }
`;

export const GET_OFFER_DEALING_REPS = gql`
  query offerDealingReps($offerSymbols: [String!]) {
    offerDealingReps(offerSymbols: $offerSymbols) {
      id
      condition
      accountUserId
      accountUser {
        name
        email
        dealingRepCondition
      }
    }
  }
`;

export const GET_DEALING_REPS = gql`
  query dealingReps($accountId: String, $offerSymbol: String) {
    dealingReps(accountId: $accountId, offerSymbol: $offerSymbol) {
      id
      type
      name
      email
      userId
      canLogin
      accountId
      dealingRepCondition
      user {
        authState
      }
    }
  }
`;

export const GET_ASSOCIATES = gql`
  query associates($accountId: String) {
    associates(accountId: $accountId) {
      id
      userId
      accountId
      title
      isDirector
      canLogin
      shareholderPercentageOwnership
      permissions
      user {
        firstName
        lastName
        acceptedPrivacy
        acceptedRDI
        acceptedTerms
        createdAt
        email
        emailVerified
        id
        requiresKYC
        kyc {
          status
          provider
          veriffId
          onfidoReport
          veriffReport
          plaidReport
        }
        name
        passportNumber
        phone
        sin
        updatedAt
      }
    }
  }
`;

export const GET_ACCOUNTS = gql`
  query accounts(
    $invested: Boolean
    $type: [String!]
    $status: [String!]
    $search: String
    $fromCreatedAt: String
    $toCreatedAt: String
    $accreditationCriteria: [String!]
    $accreditationStatus: [String!]
    $kycStatus: [String!]
    $kycProvider: [String!]
    $referrerAccountType: [String!]
    $sortDirection: String
    $sortBy: String
    $offset: Float
    $limit: Float
  ) {
    accounts(
      type: $type
      status: $status
      search: $search
      invested: $invested
      kycStatus: $kycStatus
      kycProvider: $kycProvider
      toCreatedAt: $toCreatedAt
      fromCreatedAt: $fromCreatedAt
      accreditationCriteria: $accreditationCriteria
      accreditationStatus: $accreditationStatus
      referrerAccountType: $referrerAccountType
      sortDirection: $sortDirection
      sortBy: $sortBy
      offset: $offset
      limit: $limit
    ) {
      data {
        id
        type
        name
        status
        accountNumber
        accessInvitedOffersOnly
        referrerAccount {
          type
          entity {
            name
          }
          primaryUser {
            name
          }
        }
        referrerAccountUser {
          user {
            name
          }
        }
        updatedAt
        createdAt
        requiresKYC
        kyc {
          status
          accountUsers {
            name
            userId
            user {
              id
              email
              requiresKYC
              kyc {
                status
                provider
                veriffId
                onfidoReport
                veriffReport
                plaidReport
              }
            }
          }
        }
        secondaryUserEmail
        secondaryUser {
          status
          email
          firstName
          lastName
          name
        }
        entity {
          id
          name
          kycStatus
          kycProvider
        }
        primaryUserEmail
        primaryUser {
          status
          id
          firstName
          lastName
          name
          lastLogin
          email
          phone
          acceptedRDI
        }
        secondaryUserEmail
        secondaryUser {
          status
          id
          firstName
          lastName
          name
          lastLogin
          email
          phone
          acceptedRDI
        }
        digitalSecuritiesWallets {
          blockchain
          address
          status
        }
        profile {
          ... on EntityProfile {
            name
            iconUrl
            email
          }
          ... on UserProfile {
            name
            iconUrl
            firstName
            lastName
            email
          }
        }
      }
      total
    }
  }
`;

export const GET_INVESTORS = gql`
  query accounts(
    $type: [String!]
    $search: String
    $sortDirection: String
    $sortBy: String
    $offset: Float
    $limit: Float
  ) {
    accounts(
      type: $type
      search: $search
      sortDirection: $sortDirection
      sortBy: $sortBy
      offset: $offset
      limit: $limit
    ) {
      data {
        id
        accountNumber
        type
        updatedAt
        createdAt
        primaryUserEmail
        primaryUser {
          status
          id
          firstName
          lastName
          requiresKYC
          kyc {
            status
            provider
            veriffId
            onfidoReport
            veriffReport
            plaidReport
          }
        }
        profile {
          ... on EntityProfile {
            name
            iconUrl
          }
          ... on UserProfile {
            name
            iconUrl
          }
        }
        investmentOverview
      }
      total
    }
  }
`;

export const GET_INVESTMENTS = gql`
  query investments($accountId: String, $offset: Float, $limit: Float) {
    investments(accountId: $accountId, offset: $offset, limit: $limit) {
      total
      data {
        maturityDate
        redeemedAmount
        principalPaydown
        interestReceived
        distributionAmount
        offerSymbol
        offerName
        offerIconUrl
        offer {
          type
          cardIconUrl
          tokenAddress
          fractionalInvestmentsEnabled
          tags {
            order
            type
            name
          }
          assetClass
          assetIndustry
        }
        issuerId
        issuerName
        netContribution
        netContributionConverted
        outstandingPrincipal
        outstandingPrincipalConverted
        currentPrice
        currentPriceDate
        currentPriceConverted
        currencyCode
        currencyCodeConverted
        currentValue
        currentValueConverted
        holdingUnits
        pendingUnits
        roi
        investor {
          profile {
            ... on EntityProfile {
              name
            }
            ... on UserProfile {
              name
            }
          }
        }
        walletBalances {
          amount
          walletAddress
        }
        registeredAccountBalances {
          units
          registeredAccountType
          registeredAccountTrustCompany
          netContribution
          principalPaydown
          interestReceived
          outstandingPrincipal
          maturityDate
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  query users($search: String, $offset: Float, $limit: Float) {
    users(search: $search, offset: $offset, limit: $limit) {
      data {
        id
        name
        email
        phone
        emailVerified
        acceptedTerms
        acceptedPrivacy
        acceptedRDI
        updatedAt
        createdAt
        lastLogin
        authState
        requiresKYC
        kyc {
          status
          provider
          veriffId
          onfidoReport
          veriffReport
          plaidReport
        }
      }
      total
    }
  }
`;

export const GET_DISTRIBUTION_RESOLUTIONS = gql`
  query distributionResolutions($offerSymbol: String, $distributionId: String) {
    distributionResolutions(symbol: $offerSymbol, distributionId: $distributionId) {
      id
      status
      cashPaidAt
      securitiesPaidAt
      securitiesAmount
      cashAmount
      documents {
        id
        name
        description
        envelopeId
        accountId
        envelopeId
        transactionId
        issuerId
        offerSymbol
        signatureStatus
        recipients
        createdAt
        mimeType
        url
        downloadable
      }
    }
  }
`;

export const GET_DISTRIBUTION_CHECKPOINTS = gql`
  query distributionCheckpoints(
    $symbol: String!
    $payoutDate: String
    $distributionId: String
  ) {
    distributionCheckpoints(
      symbol: $symbol
      payoutDate: $payoutDate
      distributionId: $distributionId
    ) {
      id
      payoutDate
      cashAmount
      securitiesAmount
      registryDate
      transactionId
      investor {
        profile {
          ... on EntityProfile {
            name
          }
          ... on UserProfile {
            name
          }
        }
      }
    }
  }
`;

export const GET_RECIPIENT_VIEW = gql`
  query recipientView($envelopeId: String!) {
    recipientViewUrl: recipientView(envelopeId: $envelopeId)
  }
`;

export const GET_ACCOUNT_WALLETS = gql`
  query accountWallets(
    $accountId: String
    $blockchain: String
    $offset: Float
    $limit: Float
    $receivesVirtualCurrencies: Boolean
    $receivesDigitalSecurities: Boolean
  ) {
    accountWallets(
      accountId: $accountId
      blockchain: $blockchain
      offset: $offset
      limit: $limit
      receivesVirtualCurrencies: $receivesVirtualCurrencies
      receivesDigitalSecurities: $receivesDigitalSecurities
    ) {
      data {
        accountId
        walletAddress
        receivesDigitalSecurities
        receivesVirtualCurrencies
        account {
          name
        }
        wallet {
          status
          address
          blockchain
          custodian
          createdAt
          blacklisting
          removingFromBlacklist
        }
      }
      total
    }
  }
`;

export const GET_BANK_ACCOUNTS = gql`
  query bankAccounts($accountId: String, $includePlatformAccounts: Boolean) {
    bankAccounts(
      accountId: $accountId
      includePlatformAccounts: $includePlatformAccounts
    ) {
      id
      accountId
      accountName
      accountNumber
      accountAddress
      institutionName
      institutionNumber
      institutionAddress
      swiftCode
      transitNumber
      receivesCashDistributions
      voidCheque {
        id
        name
        description
        envelopeId
        signatureStatus
        recipients
        createdAt
        mimeType
        url
        downloadable
      }
    }
  }
`;

export const GET_REGISTERED_ACCOUNTS = gql`
  query registeredAccounts($accountId: String) {
    registeredAccounts(accountId: $accountId) {
      id
      accountNumber
      letterOfAuthorization {
        envelopeId
        signatureStatus
      }
      type
    }
  }
`;

export const GET_REGISTERED_ACCOUNT = gql`
  query registeredAccount($id: String!, $accountId: String) {
    registeredAccount(id: $id, accountId: $accountId) {
      id
      accountNumber
      type
    }
  }
`;

export const SAVE_WALLET = gql`
  mutation saveWallet(
    $status: String
    $address: String
    $custodian: Boolean
    $blockchain: String
    $accountId: String
    $created: Boolean
    $receivesDigitalSecurities: Boolean
    $receivesVirtualCurrencies: Boolean
  ) {
    saveWallet(
      status: $status
      address: $address
      custodian: $custodian
      blockchain: $blockchain
      accountId: $accountId
      created: $created
      receivesVirtualCurrencies: $receivesVirtualCurrencies
      receivesDigitalSecurities: $receivesDigitalSecurities
    ) {
      id
    }
  }
`;

export const GET_ACCOUNT = gql`
  query fetchAccount($id: String) {
    account(id: $id) {
      accountNumber
      id
      type
      name
      status
      updatedAt
      suitability
      bypassKYC
      isReferralAgent
      riskRating
      myContributionRoom
      accessInvitedOffersOnly
      referralRequiresPhone
      referrerAccount {
        type
      }
      referrerAccountUser {
        user {
          name
        }
      }
      referrerAccountId
      referrerAccountUserId
      digitalSecuritiesWallets {
        blockchain
        address
        status
        custodian
      }
      requiresKYC
      kyc {
        status
        accountUsers {
          name
          userId
          user {
            id
            email
            requiresKYC
            kyc {
              status
              provider
              veriffId
              onfidoReport
              veriffReport
              plaidReport
            }
          }
        }
      }
      entityId
      entity {
        id
        name
        kycProvider
        kycStatus
      }
      primaryUserId
      primaryUser {
        status
        id
        iconUrl
        acceptedPrivacy
        acceptedRDI
        acceptedTerms
        accreditationCriteria
        accreditationStatus
        dateOfBirth
        email
        firstName
        lastName
        middleName
        name
        sin
        updatedAt
        passportNumber
        employmentStatus
        employmentOccupation
        phone
        status
        nationality
        professionalOrganisation
        registrationNumber
        residentialAddress {
          sameAsUserId
          city
          country
          postalCode
          province
          street
          unit
        }
        proofOfAddress {
          url
          mimeType
        }
        requiresKYC
        kyc {
          status
          provider
          veriffId
          onfidoReport
          veriffReport
          plaidReport
        }
      }
      secondaryUserId
      secondaryUser {
        status
        id
        iconUrl
        acceptedPrivacy
        acceptedRDI
        acceptedTerms
        accreditationCriteria
        accreditationStatus
        dateOfBirth
        email
        firstName
        lastName
        middleName
        name
        sin
        updatedAt
        passportNumber
        employmentStatus
        employmentOccupation
        phone
        status
        nationality
        professionalOrganisation
        registrationNumber
        residentialAddress {
          sameAsUserId
          city
          country
          postalCode
          province
          street
          unit
        }
        proofOfAddress {
          url
          mimeType
        }
        requiresKYC
        kyc {
          status
          provider
          veriffId
          onfidoReport
          veriffReport
          plaidReport
        }
      }
      profile {
        ... on EntityProfile {
          accreditationCriteria
          accreditationStatus
          businessIdentificationNumber
          companyAddress {
            sameAsUserId
            unit
            street
            city
            country
            province
            postalCode
          }
          proofOfAddress {
            url
            mimeType
          }
          articlesOfAssociation {
            url
            mimeType
          }
          certificateOfIncorporation {
            url
            mimeType
          }
          certificateOfAmalgamation {
            url
            mimeType
          }
          cobTime
          companySize
          countryOfIncorporation
          email
          phone
          id
          industry
          name
          placeOfBusinessOperation
          typeOfBusiness
          updatedAt
          website
          status
          iconUrl
        }
        ... on UserProfile {
          acceptedPrivacy
          acceptedRDI
          acceptedTerms
          accreditationCriteria
          accreditationStatus
          dateOfBirth
          email
          firstName
          id
          lastName
          middleName
          name
          passportNumber
          employmentStatus
          employmentOccupation
          phone
          status
          nationality
          professionalOrganisation
          registrationNumber
          residentialAddress {
            sameAsUserId
            city
            country
            postalCode
            province
            street
            unit
          }
          proofOfAddress {
            url
            mimeType
          }
          sin
          updatedAt
          requiresKYC
          kyc {
            status
            provider
            veriffId
            onfidoReport
            veriffReport
            plaidReport
          }
          iconUrl
        }
      }
    }
  }
`;

export const GET_TAGS = gql`
  query tags {
    tags {
      type
      name
    }
  }
`;

export const GET_TAG_GROUPS = gql`
  query tagGroups {
    tagGroups
  }
`;

export const GET_ENTITY = gql`
  query entity($id: String!) {
    entity(id: $id) {
      id
      kycStatus
      kycProvider
      kycNoteId
      accreditationStatus
      accreditationCriteria
    }
  }
`;

export const GET_USER = `
  query user($id: String) {
    user(id: $id) {
      acceptedPrivacy
      acceptedRDI
      acceptedTerms
      dateOfBirth
      email
      emailVerified
      firstName
      id
      lastName
      middleName
      name
      passportNumber
      employmentStatus
      employmentOccupation
      phone
      mfaEnabled
      sin
      residentialAddress {
        sameAsUserId
        city
        country
        postalCode
        province
        street
        unit
      }
      proofOfAddress {
        url
        mimeType
      }
      updatedAt
      requiresKYC
      kyc {
        status
        provider
        veriffId
        onfidoReport
        veriffReport
        plaidReport
      }
    }
  }
`;

export const GET_ACCOUNT_USER = gql`
  query fetchAccountUser($id: String) {
    accountUser(id: $id) {
      id
      type
      account {
        type
        primaryUser {
          id
          name
          residentialAddress {
            sameAsUserId
            street
            unit
            city
            province
            country
            postalCode
          }
        }
      }
      permissions
      accountId
      isDirector
      title
      shareholderPercentageOwnership
      canLogin
      user {
        acceptedPrivacy
        acceptedRDI
        acceptedTerms
        mfaEnabled
        dateOfBirth
        email
        emailVerified
        firstName
        id
        lastName
        middleName
        passportNumber
        phone
        sin
        status
        employmentStatus
        employmentOccupation
        residentialAddress {
          sameAsUserId
          street
          unit
          city
          province
          country
          postalCode
        }
        proofOfAddress {
          url
          mimeType
        }
        requiresKYC
        kyc {
          status
          provider
          veriffId
          onfidoReport
          veriffReport
          plaidReport
        }
      }
      updatedAt
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query currentUser {
    currentUser {
      id
      type
      title
      email
      accountId
      isDirector
      updatedAt
      referralCode
      createdAt
      canLogin
      requiresPhone
      requiresKYC
      permissions
      canAssumeRole @client
      canEditAccount @client
      canEditAccounts @client
      canEditOffers @client
      canInitiateInvestment @client
      canEditAccountRiskRating @client
      canEditTransactions @client
      canViewTransactions @client
      canViewAuditLogs @client
      canEditPermissions @client
      canViewAccounts @client
      canViewInvestors @client
      canExportAccountDetails @client
      userId
      user {
        id
        email
        phone
        firstName
        middleName
        lastName
        name
        emailVerified
        dateOfBirth
        acceptedPrivacy
        acceptedRDI
        acceptedTerms
        requiresRDI
        mfaEnabled
        employmentStatus
        employmentOccupation
        requiresKYC
        kyc {
          status
          provider
          veriffId
          onfidoReport
          veriffReport
          plaidReport
        }
        residentialAddress {
          sameAsUserId
          street
          unit
          city
          province
          country
          postalCode
        }
        proofOfAddress {
          url
          mimeType
        }
        status
      }
      account {
        accountNumber
        id
        type
        status
        updatedAt
        bypassKYC
        suitability
        myContributionRoom
        accessInvitedOffersOnly
        referralRequiresPhone
        referrerAccount {
          type
        }
        referrerAccountUser {
          user {
            name
          }
        }
        referrerAccountId
        referrerAccountUserId
        digitalSecuritiesWallets {
          blockchain
          address
          status
          custodian
        }
        investmentOverview
        requiresKYC
        kyc {
          status
          accountUsers {
            name
            userId
            user {
              id
              email
              requiresKYC
              kyc {
                status
                provider
                veriffId
                onfidoReport
                veriffReport
                plaidReport
              }
            }
          }
        }
        primaryUser {
          status
          id
          iconUrl
          acceptedPrivacy
          acceptedRDI
          acceptedTerms
          accreditationCriteria
          accreditationStatus
          dateOfBirth
          email
          firstName
          lastName
          middleName
          name
          sin
          updatedAt
          passportNumber
          employmentStatus
          employmentOccupation
          phone
          status
          nationality
          professionalOrganisation
          registrationNumber
          residentialAddress {
            sameAsUserId
            city
            country
            postalCode
            province
            street
            unit
          }
          proofOfAddress {
            url
            mimeType
          }
          requiresKYC
          kyc {
            status
            provider
            veriffId
            onfidoReport
            veriffReport
            plaidReport
          }
        }
        secondaryUser {
          status
          id
          iconUrl
          acceptedPrivacy
          acceptedRDI
          acceptedTerms
          accreditationCriteria
          accreditationStatus
          dateOfBirth
          email
          firstName
          lastName
          middleName
          name
          sin
          updatedAt
          passportNumber
          employmentStatus
          employmentOccupation
          phone
          status
          nationality
          professionalOrganisation
          registrationNumber
          residentialAddress {
            sameAsUserId
            city
            country
            postalCode
            province
            street
            unit
          }
          proofOfAddress {
            url
            mimeType
          }
          requiresKYC
          kyc {
            status
            provider
            veriffId
            onfidoReport
            veriffReport
            plaidReport
          }
        }
        profile {
          ... on EntityProfile {
            status
            accreditationCriteria
            accreditationStatus
            businessIdentificationNumber
            companyAddress {
              sameAsUserId
              unit
              street
              city
              country
              province
              postalCode
            }
            proofOfAddress {
              url
              mimeType
            }
            articlesOfAssociation {
              url
              mimeType
            }
            certificateOfIncorporation {
              url
              mimeType
            }
            certificateOfAmalgamation {
              url
              mimeType
            }
            cobTime
            companySize
            countryOfIncorporation
            email
            phone
            id
            industry
            name
            placeOfBusinessOperation
            typeOfBusiness
            updatedAt
            website
            status
            iconUrl
          }
          ... on UserProfile {
            status
            acceptedPrivacy
            acceptedRDI
            acceptedTerms
            accreditationCriteria
            accreditationStatus
            dateOfBirth
            email
            firstName
            id
            lastName
            middleName
            name
            passportNumber
            employmentStatus
            employmentOccupation
            phone
            status
            nationality
            professionalOrganisation
            registrationNumber
            residentialAddress {
              sameAsUserId
              city
              country
              postalCode
              province
              street
              unit
            }
            proofOfAddress {
              url
              mimeType
            }
            sin
            updatedAt
            requiresKYC
            kyc {
              status
              provider
              veriffId
              onfidoReport
              veriffReport
              plaidReport
            }
            iconUrl
          }
        }
      }
    }
  }
`;

export const GET_OFFER_UPDATES = gql`
  query offerUpdates($symbol: String!, $isPublished: Boolean) {
    offerUpdates(symbol: $symbol, isPublished: $isPublished) {
      id
      type
      access
      content
      nav {
        id
        value
        valuedAt
      }
      document {
        id
        name
        description
        envelopeId
        signatureStatus
        recipients
        uploadedAt
        mimeType
        url
        downloadable
      }
      video {
        id
        name
        description
        url
      }
      isPublished
      updatedAt
      createdAt
      offerSymbol
    }
  }
`;

export const GET_VIDEOS = gql`
  query videos($symbol: String!) {
    videos(symbol: $symbol) {
      id
      access
      name
      description
      url
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation saveFileToS3($file: Upload!, $offerSymbol: String!) {
    url: saveFileToS3(file: $file, offerSymbol: $offerSymbol)
  }
`;

export const GET_INVESTMENT_FLOW_OFFER = gql`
  query offer($symbol: String!) {
    offer(symbol: $symbol) {
      isSoldOut
      status
      type
      heroUrl
      name
      tags {
        order
        type
        name
      }
      iconUrl
      cardIconUrl
      symbol
      blockchain
      description
      offerSize
      currencyCode
      groupId
      dealingRepDisclosure
      fractionalInvestmentsEnabled
      investmentMultiple
      investmentAmountRestrictions {
        type
        amount
        condition
      }
      supportedPaymentMethods
      supportedPaymentCurrencies {
        currencyCode
        exchangeRate
        paymentMethods {
          paymentMethod
          paymentInstructions
          paymentReceivingBankAccountId
          paymentReceivingWalletAddress
          billPaymentPayeeName
          billPaymentAccountNumber
          supportedRegisteredAccountTrustCompanies
          eTransferEmail
        }
      }
      subscriptionTemplates {
        id
        status
      }
      nav {
        value
      }
      distributions {
        id
        paymentMethod
        active
      }
      allowEligibleInvestors
      allowNonEligibleAndNonAccreditedInvestors
      allowFFBE
      requiresPassportNumber
      requiresSIN
      requireSINForCountries
      myInvestment {
        contributionAmount
      }
      invested @client
      issuerId
      issuer {
        name
        entity {
          name
        }
      }
      allowInvitesOnly
      myInvitationStatus
      residenceRestrictions
      hasDistributions
      liquidityRisk
      riskTolerance
      riskCapacity
      investmentObjectives
    }
  }
`;

export const GET_INVESTMENT_FLOW_TRANSACTION = gql`
  query transaction($id: String!) {
    transaction(id: $id) {
      id
      type
      offerSymbol
      status
      amount
      nav {
        value
      }
      paymentSource
      paymentReceivingWalletAddress
      paymentMethod
      paymentCurrencyCode
      paymentCurrencyExchangeRate
      paymentWallet {
        status
      }
      paymentTransactionHash
      distributionCheckpoint {
        id
        nav {
          value
          valuedAt
        }
        registryDate
        cashAmount
        securitiesAmount
      }
      referralNoticeDocument {
        id
        name
        description
        sentAt
        acknowledgedAt
        recipients
        uploadedAt
        mimeType
        url
        downloadable
      }
      prefersDRIP
      usedFFBE
      ffbaName
      ffbaRelationship
      ffbaFamilyRelationship
      ffbaPosition
      ffbaDuration
      ffbaEmail
      ffbaPhone
      investorId
      envelopes {
        envelopeId
        signatureStatus
      }
      signatureStatus
      dealingRep {
        name
      }
      offer {
        isSoldOut
        status
        type
        heroUrl
        name
        tags {
          order
          type
          name
        }
        iconUrl
        cardIconUrl
        symbol
        blockchain
        description
        offerSize
        currencyCode
        dealingRepDisclosure
        fractionalInvestmentsEnabled
        investmentAmountRestrictions {
          type
          amount
          condition
        }
        supportedPaymentMethods
        supportedPaymentCurrencies {
          currencyCode
          exchangeRate
          paymentMethods {
            paymentMethod
            paymentInstructions
            paymentReceivingBankAccountId
            paymentReceivingWalletAddress
            billPaymentPayeeName
            billPaymentAccountNumber
            supportedRegisteredAccountTrustCompanies
            eTransferEmail
          }
        }
        subscriptionTemplates {
          id
          status
        }
        nav {
          value
        }
        distributions {
          id
          paymentMethod
          active
        }
        allowEligibleInvestors
        allowNonEligibleAndNonAccreditedInvestors
        allowFFBE
        requiresPassportNumber
        requiresSIN
        requireSINForCountries
        myInvestment {
          contributionAmount
        }
        invested @client
        issuerId
        issuer {
          name
          entity {
            name
          }
        }
        groupId
        allowInvitesOnly
        myInvitationStatus
        residenceRestrictions
        hasDistributions
      }
    }
  }
`;

export const GET_TRANSACTION = gql`
  query transaction($id: String!) {
    transaction(id: $id) {
      id
      type
      status
      amount
      serviceType
      offerSymbol
      offerType
      principalAmount
      interestAmount
      signatureStatus
      paymentMethod
      paymentCurrencyCode
      paymentCurrencyExchangeRate
      paymentTransactionHash
      paymentReceivingWalletAddress
      blockchainTransactionHash
      completedSuitabilityNotes
      suitabilityNotes
      suitabilityMismatch
      acknowledgedSuitabilityRiskAt
      usingBorrowedFunds
      acknowledgedUsingBorrowedFundsAt
      acknowledgedDRConflictOfInterestAt
      prefersDRIP
      usedFFBE
      ffbaName
      ffbaRelationship
      ffbaFamilyRelationship
      ffbaPosition
      ffbaDuration
      ffbaEmail
      ffbaPhone
      nav {
        value
        valuedAt
      }
      referralNoticeDocument {
        id
        name
        description
        sentAt
        acknowledgedAt
        recipients
        uploadedAt
        mimeType
        url
        downloadable
      }
      offer {
        type
        issuerId
        groupId
        fractionalInvestmentsEnabled
        symbol
        name
        blockchain
        networkId
        issuance
        currencyCode
        tokenAddress
        nav {
          value
          valuedAt
        }
        supportedPaymentCurrencies {
          currencyCode
          exchangeRate
          paymentMethods {
            paymentMethod
            paymentInstructions
            paymentReceivingBankAccountId
            paymentReceivingWalletAddress
            billPaymentPayeeName
            billPaymentAccountNumber
            supportedRegisteredAccountTrustCompanies
          }
        }
        issuer {
          accountNumber
          id
          type
          status
          updatedAt
          suitability
          accessInvitedOffersOnly
          bypassKYC
          referralRequiresPhone
          primaryUser {
            status
            id
            email
            firstName
            lastName
            requiresKYC
            kyc {
              status
              provider
              veriffId
              onfidoReport
              veriffReport
              plaidReport
            }
          }
          profile {
            ... on EntityProfile {
              accreditationCriteria
              accreditationStatus
              businessIdentificationNumber
              companyAddress {
                sameAsUserId
                unit
                street
                city
                country
                province
                postalCode
              }
              proofOfAddress {
                url
                mimeType
              }
              articlesOfAssociation {
                url
                mimeType
              }
              certificateOfIncorporation {
                url
                mimeType
              }
              certificateOfAmalgamation {
                url
                mimeType
              }
              cobTime
              companySize
              countryOfIncorporation
              email
              phone
              id
              industry
              name
              placeOfBusinessOperation
              typeOfBusiness
              updatedAt
              website
              status
              iconUrl
            }
          }
        }
      }
      dealingRepId
      dealingRep {
        name
      }
      investorId
      investor {
        accountNumber
        id
        type
        status
        updatedAt
        suitability
        accessInvitedOffersOnly
        bypassKYC
        referralRequiresPhone
        digitalSecuritiesWallets {
          blockchain
          address
          status
          custodian
        }
        requiresKYC
        kyc {
          status
          accountUsers {
            name
            userId
            user {
              id
              email
              requiresKYC
              kyc {
                status
                provider
                veriffId
                onfidoReport
                veriffReport
                plaidReport
              }
            }
          }
        }
        referrerAccountId
        referrerAccountUserId
        referrerAccount {
          type
        }
        primaryUser {
          status
          id
          iconUrl
          acceptedPrivacy
          acceptedRDI
          acceptedTerms
          accreditationCriteria
          accreditationStatus
          dateOfBirth
          email
          firstName
          lastName
          middleName
          name
          sin
          updatedAt
          passportNumber
          employmentStatus
          employmentOccupation
          phone
          status
          nationality
          professionalOrganisation
          registrationNumber
          residentialAddress {
            sameAsUserId
            city
            country
            postalCode
            province
            street
            unit
          }
          proofOfAddress {
            url
            mimeType
          }
          requiresKYC
          kyc {
            status
            provider
            veriffId
            onfidoReport
            veriffReport
            plaidReport
          }
        }
        secondaryUser {
          status
          id
          iconUrl
          acceptedPrivacy
          acceptedRDI
          acceptedTerms
          accreditationCriteria
          accreditationStatus
          dateOfBirth
          email
          firstName
          lastName
          middleName
          name
          sin
          updatedAt
          passportNumber
          employmentStatus
          employmentOccupation
          phone
          status
          nationality
          professionalOrganisation
          registrationNumber
          residentialAddress {
            sameAsUserId
            city
            country
            postalCode
            province
            street
            unit
          }
          proofOfAddress {
            url
            mimeType
          }
          requiresKYC
          kyc {
            status
            provider
            veriffId
            onfidoReport
            veriffReport
            plaidReport
          }
        }
        profile {
          ... on EntityProfile {
            accreditationCriteria
            accreditationStatus
            businessIdentificationNumber
            companyAddress {
              sameAsUserId
              unit
              street
              city
              country
              province
              postalCode
            }
            proofOfAddress {
              url
              mimeType
            }
            articlesOfAssociation {
              url
              mimeType
            }
            certificateOfIncorporation {
              url
              mimeType
            }
            certificateOfAmalgamation {
              url
              mimeType
            }
            cobTime
            companySize
            countryOfIncorporation
            email
            phone
            id
            industry
            name
            placeOfBusinessOperation
            typeOfBusiness
            updatedAt
            website
            status
            iconUrl
          }
          ... on UserProfile {
            acceptedPrivacy
            acceptedRDI
            acceptedTerms
            accreditationCriteria
            accreditationStatus
            dateOfBirth
            email
            firstName
            id
            lastName
            middleName
            name
            passportNumber
            employmentStatus
            employmentOccupation
            phone
            status
            nationality
            professionalOrganisation
            registrationNumber
            residentialAddress {
              sameAsUserId
              city
              country
              postalCode
              province
              street
              unit
            }
            proofOfAddress {
              url
              mimeType
            }
            sin
            updatedAt
            requiresKYC
            kyc {
              status
              provider
              veriffId
              onfidoReport
              veriffReport
              plaidReport
            }
            iconUrl
          }
        }
      }
      beneficiaryWallet {
        address
        status
        custodian
        whitelisting
      }
      distributionCheckpoint {
        id
        nav {
          value
          valuedAt
        }
        registryDate
        cashAmount
        securitiesAmount
      }
      documents {
        id
        name
        description
        envelopeId
        transactionId
        issuerId
        offerSymbol
        accountId
        signatureStatus
        recipients
        createdAt
        uploadedAt
        mimeType
        url
        downloadable
      }
      paymentSource
      paymentSourceStatus
      paymentSourceApproved
      paymentBankAccount {
        id
        status
        accountNumber
        rejectedReason
      }
      paymentWallet {
        id
        address
        status
        rejectedReason
      }
      paymentTransactionHash
      paymentRegisteredAccount {
        id
        type
        status
        accountNumber
        trustCompany
        letterOfAuthorization {
          id
          name
          description
          envelopeId
          signatureStatus
          recipients
          uploadedAt
          mimeType
          url
          downloadable
          access
          accountId
          type
        }
      }
      transferredFromOfferSymbol
      transferredFromOfferAtNAVValue
      transferredFromOfferAtNAVValuedAt
      transferredFromAccountId
      transferredToAccountId
      transferredFromAccountType
      transferredToAccountType
      updatedAt
      createdAt
      completedAt
      distributionDate
      maturityDate
    }
  }
`;

export const GET_OFFER_INVITES = gql`
  query offerInvites(
    $symbol: String!
    $offset: Float
    $limit: Float
    $referred: Boolean
    $search: String
  ) {
    offerInvites(
      symbol: $symbol
      offset: $offset
      limit: $limit
      referred: $referred
      search: $search
    ) {
      id
      status
      accountType
      entityName
      primaryUserName
      primaryUserEmail
      account {
        referrerAccount {
          type
          entity {
            name
          }
          primaryUser {
            name
          }
        }
        referrerAccountUser {
          user {
            name
          }
        }
      }
      investmentOverview(offerSymbol: $symbol)
    }
  }
`;

export const GET_INVESTORS_WITH_OFFER_ACCESS = gql`
  query investorsWithOfferAccess(
    $offerSymbol: String!
    $offset: Float
    $limit: Float
    $search: String
    $referred: Boolean
  ) {
    investorsWithOfferAccess(
      offerSymbol: $offerSymbol
      offset: $offset
      limit: $limit
      search: $search
      referred: $referred
    ) {
      id
      type
      primaryUserEmail
      profile {
        ... on EntityProfile {
          name
        }
        ... on UserProfile {
          name
        }
      }
      referrerAccount {
        type
        entity {
          name
        }
        primaryUser {
          name
        }
      }
      referrerAccountUser {
        user {
          name
        }
      }
      investmentOverview(offerSymbol: $offerSymbol)
    }
  }
`;

export const GET_DOCUMENTS = gql`
  query documents(
    $type: String
    $generated: Boolean
    $offerSymbol: String
    $accountId: String
    $onlyRelatedToAccount: Boolean
    $offset: Float
    $limit: Float
    $fromDate: String
    $toDate: String
    $search: String
    $sortBy: String
    $generationId: String
  ) {
    documents(
      generationId: $generationId
      type: $type
      generated: $generated
      onlyRelatedToAccount: $onlyRelatedToAccount
      offerSymbol: $offerSymbol
      accountId: $accountId
      offset: $offset
      limit: $limit
      fromDate: $fromDate
      toDate: $toDate
      search: $search
      sortBy: $sortBy
    ) {
      data {
        id
        generationId
        accessible
        name
        names
        url
        access
        description
        offerSymbol
        offer {
          name
        }
        transactionId
        accountId
        issuerId
        uploadedAt
        envelopeId
        envelopeStatus
        recipients
        mimeType
        downloadable
        signatureStatus
        type
        order
      }
      total
    }
  }
`;

export const GET_KYC_DOCUMENTS = gql`
  query kycDocuments($userId: String, $entityId: String) {
    kycDocuments(userId: $userId, entityId: $entityId) {
      id
      url
      name
      mimeType
    }
  }
`;

export const GET_DOCUMENT = gql`
  query document($id: String!) {
    document(id: $id) {
      id
      name
      url
      access
      description
      offerSymbol
      offer {
        name
      }
      transactionId
      accountId
      uploadedAt
      envelopeId
      recipients
      mimeType
      downloadable
      signatureStatus
    }
  }
`;

export const SAVE_ENGAGEMENT = gql`
  mutation saveOfferEngagement(
    $activity: String!
    $offerSymbol: String!
    $label: String
    $startDate: String
    $endDate: String
  ) {
    saveOfferEngagement(
      activity: $activity
      offerSymbol: $offerSymbol
      label: $label
      startDate: $startDate
      endDate: $endDate
    ) {
      id
    }
  }
`;

export const NOTIFY_ADMIN_TRANSACTION_CREATED_WITHOUT_SIGNING = gql`
  mutation notifyAdminInvestorCreatedTransactionWithoutSigning(
    $offerSymbol: String!
    $transactionId: String!
  ) {
    notifyAdminInvestorCreatedTransactionWithoutSigning(
      offerSymbol: $offerSymbol
      transactionId: $transactionId
    )
  }
`;

export const REQUEST_OFFER_INVITE = gql`
  mutation requestOfferInvite($offerSymbol: String!) {
    requestOfferInvite(offerSymbol: $offerSymbol) {
      id
    }
  }
`;

export const UPDATE_OFFER_INVITE = gql`
  mutation updateOfferInvite($id: String!, $status: String!) {
    updateOfferInvite(id: $id, status: $status)
  }
`;

export const GET_OFFER = gql`
  query offer($symbol: String!, $invited: String) {
    offer(symbol: $symbol, invited: $invited) {
      isSoldOut
      heroUrl
      symbol
      status
      iconUrl
      cardIconUrl
      groupId
      name
      description
      offerSize
      currencyCode
      content
      tags {
        order
        type
        name
      }
      contactAddress
      calendlyLink
      contactEmails
      contactPhones
      videos {
        id
      }
      myInvitationStatus
      myEngagement {
        favorited
      }
      myInvestment {
        contributionAmount
        netContribution
        netContributionConverted
        outstandingPrincipal
        outstandingPrincipalConverted
        currentPrice
        currentPriceDate
        currentPriceConverted
        currencyCode
        currencyCodeConverted
        pendingUnits
        roi
      }
      invested @client
      issuer {
        entity {
          name
        }
      }
      allowInvitesOnly
      navs {
        value
        valuedAt
      }
      documents {
        id
      }
      updates {
        id
      }
      liquidityRisk
      riskTolerance
      riskCapacity
      investmentObjectives
    }
  }
`;

export const GET_OFFER_MANAGEMENT = gql`
  query offer($symbol: String!) {
    offer(symbol: $symbol) {
      symbol
      order
      securityCode
      issuerCanEdit
      cardIconUrl
      heroUrl
      access
      iconUrl
      blockchain
      deploying
      contactAddress
      calendlyLink
      contactEmails
      contactPhones
      nav {
        value
      }
      navs {
        id
        value
        valuedAt
        subscriptionNAV
      }
      name
      status
      content
      currencyCode
      description
      fractionalInvestmentsEnabled
      investmentMultiple
      issuance
      issuerId
      dealingRepDisclosure
      networkId
      offerSize
      raisedOutsidePlatform
      totalRaisedOutsidePlatform
      investmentAmountRestrictions {
        type
        amount
        condition
      }
      supportedPaymentMethods
      supportedPaymentCurrencies {
        currencyCode
        exchangeRate
        paymentMethods {
          paymentMethod
          paymentInstructions
          paymentReceivingBankAccountId
          paymentReceivingWalletAddress
          billPaymentPayeeName
          billPaymentAccountNumber
          supportedRegisteredAccountTrustCompanies
          eTransferEmail
        }
      }
      tagGroups
      tags {
        order
        type
        name
      }
      tokenAddress
      tokenSource
      type
      allowEligibleInvestors
      allowNonEligibleAndNonAccreditedInvestors
      allowFFBE
      requiresSIN
      requireSINForCountries
      requiresPassportNumber
      distributions {
        id
        active
        paymentMethod
      }
      subscriptionTemplates {
        id
        offerSymbol
        name
        status
        templateId
        condition
        signers {
          id
          roleName
          accountUserId
        }
      }
      allowInvitesOnly
      residenceRestrictions
      issuer {
        entity {
          iconUrl
        }
      }
      assetClass
      assetIndustry
      hasDistributions
      maturityDate
      debtMaturityType
      monthsToMaturity
      groupId
      commissionWithoutDRIPType
      commissionWithDRIPType
      commissionWithoutDRIP
      commissionWithDRIP
      showCountDownClock
      countDownClockEndDateAndTime
      overview
      showProgressBar
      liquidityRisk
      riskTolerance
      riskCapacity
      investmentObjectives
    }
  }
`;

export const GET_ENGAGEMENTS = gql`
  query offerEngagements(
    $offerSymbols: [String!]
    $investorId: String
    $issuerId: String
    $offset: Float
    $limit: Float
    $search: String
  ) {
    offerEngagements(
      offerSymbols: $offerSymbols
      investorId: $investorId
      issuerId: $issuerId
      offset: $offset
      limit: $limit
      search: $search
    ) {
      total
      data {
        id
        offer {
          symbol
          name
        }
        account {
          type
          name
          email
        }
        favorited
        sharedAt
        viewedAt
        abandonedAt
        timeSpent
      }
    }
  }
`;

export const GET_TRANSACTIONS = gql`
  query transactions(
    $ids: [String!]
    $symbols: [String!]
    $investorIds: [String!]
    $issuerIds: [String!]
    $statuses: [String!]
    $issuances: [String!]
    $types: [String!]
    $referrerAccountType: [String!]
    $referrerIds: [String!]
    $search: String
    $fromCompletedAt: String
    $toCompletedAt: String
    $limit: Float
    $offset: Float
    $sortDirection: String
    $sortBy: String
    $kycStatus: String
    $kycProvider: String
    $offerTypes: [String!]
    $prefersDRIP: Boolean
    $incompleteSuitabilityNotes: Boolean
  ) {
    transactions(
      ids: $ids
      symbols: $symbols
      investorIds: $investorIds
      issuerIds: $issuerIds
      statuses: $statuses
      issuances: $issuances
      types: $types
      referrerAccountType: $referrerAccountType
      referrerIds: $referrerIds
      fromCompletedAt: $fromCompletedAt
      toCompletedAt: $toCompletedAt
      search: $search
      limit: $limit
      offset: $offset
      sortDirection: $sortDirection
      sortBy: $sortBy
      kycStatus: $kycStatus
      kycProvider: $kycProvider
      offerTypes: $offerTypes
      prefersDRIP: $prefersDRIP
      incompleteSuitabilityNotes: $incompleteSuitabilityNotes
    ) {
      total
      data {
        id
        type
        amount
        status
        usedFFBE
        createdAt
        completedAt
        offerSymbol
        paymentMethod
        principalAmount
        interestAmount
        distributionDate
        signatureStatus
        paymentCurrencyCode
        paymentCurrencyExchangeRate
        paymentTransactionHash
        blockchainTransactionHash
        completedSuitabilityNotes
        kyc {
          status
          accountUsers {
            name
            userId
            user {
              id
              email
              requiresKYC
              kyc {
                status
                provider
                veriffId
                onfidoReport
                veriffReport
                plaidReport
              }
            }
          }
        }
        referralNoticeDocument {
          sentAt
          acknowledgedAt
        }
        paymentRegisteredAccount {
          id
          type
          status
          accountNumber
          trustCompany
        }
        nav {
          value
          valuedAt
        }
        offer {
          id
          type
          name
          symbol
          currencyCode
          fractionalInvestmentsEnabled
          nav {
            value
            valuedAt
          }
          issuer {
            name
            accountNumber
          }
        }
        distributionCheckpoint {
          nav {
            value
            valuedAt
          }
          cashAmount
          securitiesAmount
        }
        dealingRepId
        dealingRep {
          name
          email
        }
        investor {
          name
          accountNumber
          primaryUserEmail
          secondaryUserEmail
          requiresKYC
          entity {
            kycStatus
            kycProvider
            kycNoteId
            accreditationStatus
            accreditationCriteria
          }
          primaryUser {
            acceptedRDI
            accreditationStatus
            accreditationCriteria
          }
          referrerAccountId
          referrerAccount {
            type
            entity {
              name
            }
            primaryUser {
              name
              email
            }
          }
          referrerAccountUser {
            user {
              name
            }
          }
          kyc {
            status
            accountUsers {
              name
              userId
              user {
                id
                email
                requiresKYC
                kyc {
                  status
                  provider
                  veriffId
                  onfidoReport
                  veriffReport
                  plaidReport
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_KYC = gql`
  query kyc($id: String, $userId: String) {
    kyc(id: $id, userId: $userId) {
      id
      userId
      status
      provider
      user {
        id
        firstName
        middleName
        lastName
        name
        email
      }
      veriffReport
      onfidoReport
      pep
      warnings
      sanctions
      fitnessProbity
      adverseMedia
      documentType
      documentNumber
      documentCountry
      documentValidFrom
      documentValidTo
      secondaryDocument
      kycHitsReviewNoteId
      kycHitsReviewStatus
      kycHitsReviewNote {
        id
        content
      }
    }
  }
`;

export const GET_KYCS = gql`
  query kycs($accountId: String) {
    kycs(accountId: $accountId) {
      id
      userId
      status
      provider
      user {
        firstName
        middleName
        lastName
        name
        email
      }
      veriffReport
      onfidoReport
      pep
      warnings
      sanctions
      fitnessProbity
      adverseMedia
      documentType
      documentNumber
      documentCountry
      documentValidFrom
      documentValidTo
      secondaryDocument
      kycHitsReviewNoteId
      kycHitsReviewStatus
      kycHitsReviewNote {
        id
        content
      }
    }
  }
`;

export const GET_OFFER_FOR_TRANSACTION = gql`
  query offer($symbol: String!) {
    offer(symbol: $symbol) {
      type
      issuerId
      fractionalInvestmentsEnabled
      symbol
      name
      blockchain
      networkId
      issuance
      currencyCode
      tokenAddress
      nav {
        value
        valuedAt
      }
      groupId
      issuer {
        accountNumber
        id
        type
        status
        updatedAt
        suitability
        accessInvitedOffersOnly
        bypassKYC
        referralRequiresPhone
        digitalSecuritiesWallets {
          blockchain
          address
          status
        }
        primaryUser {
          status
          id
          email
          firstName
          lastName
          requiresKYC
          kyc {
            status
            provider
            veriffId
            onfidoReport
            veriffReport
            plaidReport
          }
        }
        profile {
          ... on EntityProfile {
            id
            email
            phone
            industry
            name
            accreditationCriteria
            accreditationStatus
            businessIdentificationNumber
            companyAddress {
              sameAsUserId
              unit
              street
              city
              country
              province
              postalCode
            }
            cobTime
            companySize
            countryOfIncorporation
            placeOfBusinessOperation
            typeOfBusiness
            updatedAt
            website
            status
            iconUrl
          }
        }
      }
      liquidityRisk
      riskTolerance
      riskCapacity
      investmentObjectives
    }
  }
`;

export const GET_OFFER_GROUP = gql`
  query offerGroup($id: String!) {
    offerGroup(id: $id) {
      id
      name
      description
      order
      iconUrl
      heroUrl
      cardIconUrl
      issuerId
      groupType
      tagGroups
      tags {
        type
        name
        order
      }
      offers {
        id
        symbol
        name
        description
        issuer {
          id
          accountNumber
          entity {
            name
          }
        }
      }
    }
  }
`;

export const GET_OFFER_GROUPS = gql`
  query offerGroups {
    offerGroups {
      id
      name
      description
      iconUrl
      heroUrl
      cardIconUrl
      issuerId
      offers {
        id
        symbol
        name
        description
      }
    }
  }
`;

export const GET_MARKETPLACE = gql`
  query market(
    $invited: String
    $search: String
    $tagGroups: [String!]
    $groupId: String
    $status: [String!]
  ) {
    market(
      invited: $invited
      search: $search
      tagGroups: $tagGroups
      status: $status
      groupId: $groupId
    ) {
      ... on Offer {
        status
        symbol
        name
        iconUrl
        heroUrl
        cardIconUrl
        description
        currencyCode
        offerSize
        raisedOutsidePlatform
        totalRaisedOutsidePlatform
        totalContributionAmount
        completionPercentage @client
        tags {
          order
          type
          name
        }
        issuer {
          entity {
            name
            iconUrl
          }
        }
        isSoldOut
        showCountDownClock
        countDownClockEndDateAndTime
        showProgressBar
      }
      ... on OfferGroup {
        id
        name
        iconUrl
        heroUrl
        cardIconUrl
        description
        issuerId
        issuer {
          entity {
            name
            iconUrl
          }
        }
        tags {
          order
          type
          name
        }
        offers {
          status
          symbol
          name
          iconUrl
          cardIconUrl
          description
          currencyCode
          offerSize
          raisedOutsidePlatform
          totalRaisedOutsidePlatform
          totalContributionAmount
          completionPercentage @client
          tags {
            order
            type
            name
          }
          issuer {
            entity {
              name
              iconUrl
            }
          }
          isSoldOut
          showCountDownClock
          countDownClockEndDateAndTime
          showProgressBar
        }
      }
    }
  }
`;

export const GET_OFFER_REFERRERS = gql`
  query offerReferrers(
    $offerSymbols: [String!]
    $referralAgentIds: [String!]
    $ids: [String!]
  ) {
    offerReferrers(
      offerSymbols: $offerSymbols
      referralAgentIds: $referralAgentIds
      ids: $ids
    ) {
      id
      commission
      referralAgentId
      offerSymbol
      referralAgent {
        type
        entity {
          name
        }
        primaryUser {
          firstName
          lastName
          email
        }
        profile {
          ... on EntityProfile {
            name
          }
          ... on UserProfile {
            name
          }
        }
      }
    }
  }
`;

export const GET_INVITED_ACCOUNTS = gql`
  query invitedAccounts($accountId: String!) {
    invitedAccounts(accountId: $accountId) {
      id
      type
      primaryUserEmail
      entityName
      primaryUserName
      entityPhone
      primaryUserPhone
    }
  }
`;

export const GET_OFFER_PAYMENT_INSTRUCTIONS = gql`
  query offerPaymentInstructions($issuerId: String) {
    offerPaymentInstructions(issuerId: $issuerId) {
      id
      accountId
      currencies
      paymentMethods
      instructions
      billPaymentPayeeName
      billPaymentAccountNumber
      eTransferEmail
    }
  }
`;

export const GET_NOTES = gql`
  query notes(
    $type: String
    $accountIds: [String!]
    $entityIds: [String!]
    $userIds: [String!]
    $limit: Float
    $fromDate: String
    $toDate: String
  ) {
    notes(
      type: $type
      limit: $limit
      toDate: $toDate
      fromDate: $fromDate
      userIds: $userIds
      entityIds: $entityIds
      accountIds: $accountIds
    ) {
      total
      data {
        id
        type
        content
        userId
        entityId
        accountId
        transactionId
        transactionTitle
        updatedAt
        createdAt
      }
    }
  }
`;

export const SAVE_DOCUMENT = gql`
mutation saveDocument(
  $id: String
  $type: String
  $name: String
  $description: String
  $access: String
  $file: Upload
  $offerSymbol: String
  $accountId: String
  $transactionId: String
  $issuerId: String
  $downloadable: Boolean
  $postUpdate: Boolean
  $envelopeId: String
  $userId: String
  $entityId: String
  $forKYC: Boolean
) {
  saveDocument(
    id: $id
    type: $type
    name: $name
    file: $file
    access: $access
    description: $description
    offerSymbol: $offerSymbol
    issuerId: $issuerId
    accountId: $accountId
    transactionId: $transactionId
    downloadable: $downloadable
    postUpdate: $postUpdate
    envelopeId: $envelopeId
    userId: $userId
    entityId: $entityId
    forKYC: $forKYC
  )
}
`;